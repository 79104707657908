import { Maybe } from '@/common/types';
import {
  type NewWorkOrderInput,
  type UpdateWorkOrderInput,
  type WorkOrderPriority,
  type WorkOrderStatus,
} from '@/graphql/types';
import { ICreateCheckListInput, IUpdateCheckListInput } from '@/modules/checkList';
import { toastPromiseOptions, useToast } from '@/utils/atoms/toast';
import useTranslation from '@/utils/i18n/useTranslation';
import { useCallback } from 'react';
import {
  useAddWorkOrderMutation,
  useUpdateWorkOrderMutation,
} from '../graphql/workOrders.generated';
import type {
  IWorkOrder,
  IWorkOrderAssigneeInput,
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFileValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
  IWorkOrderPart,
  IWorkOrderScheduleDueDateInput,
  IWorkOrderScheduleMonthlyDateInput,
  IWorkOrderScheduleMonthlyDayOfWeekDateInput,
  IWorkOrderScheduleWeeklyDateInput,
  IWorkOrderStoppage,
} from '../types/workOrder';

export type WorkOrderFormBaseDataType = {
  title?: string | undefined;
  assetId?: number | undefined;
  productId?: number | undefined;
  description?: string | undefined;
  dueDate?: string | null;
  status?: WorkOrderStatus;
  priority?: WorkOrderPriority;
  stoppage?: IWorkOrderStoppage;
  stoppageReasonId?: number | null;
  parts: IWorkOrderPart[];
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[];
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[];
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[];
  customFieldUserValues: IWorkOrderCustomFieldUserValueInput[];
  customFieldFileValues: IWorkOrderCustomFieldFileValueInput[];
  assignees: IWorkOrderAssigneeInput[];
  groupIds?: number[];
};

// 名前変える
export type WorkOrderFormDataType = WorkOrderFormBaseDataType & {
  checkLists?: ICreateCheckListInput[];
  templateId: number;
  scheduleDueDateInput?: IWorkOrderScheduleDueDateInput;
  scheduleWeeklyDateInput?: IWorkOrderScheduleWeeklyDateInput;
  scheduleMonthlyDateInput?: IWorkOrderScheduleMonthlyDateInput;
  scheduleMonthlyDayOFWeekDateInput?: IWorkOrderScheduleMonthlyDayOfWeekDateInput;
};

export type UpdateWorkOrderFormDataType = WorkOrderFormBaseDataType & {
  id: number;
  checkLists?: IUpdateCheckListInput[];
};

type ReturnType = {
  addWorkOrder: (payload: WorkOrderFormDataType) => Promise<Maybe<IWorkOrder>>;
  updateWorkOrder: (payload: UpdateWorkOrderFormDataType) => Promise<Maybe<IWorkOrder>>;
};

export const useAddWorkOrder = (): ReturnType => {
  const { toastPromise } = useToast();

  const [addWorkOrderMutation] = useAddWorkOrderMutation();
  const [updateWorkOrderMutation] = useUpdateWorkOrderMutation();

  const { t } = useTranslation();

  const addWorkOrder = useCallback(
    async (payload: WorkOrderFormDataType) => {
      const { data } = await toastPromise(
        addWorkOrderMutation({
          variables: { newWorkOrderInput: payload as NewWorkOrderInput },
        }),
        toastPromiseOptions(t, 'create', 'task'),
        true
      );
      if (!data) throw new Error('Data Error');
      return data.addWorkOrder as IWorkOrder;
    },
    [addWorkOrderMutation, t, toastPromise]
  );

  const updateWorkOrder = useCallback(
    async (payload: UpdateWorkOrderFormDataType) => {
      const { data } = await toastPromise(
        updateWorkOrderMutation({
          variables: { updateWorkOrderInput: payload as UpdateWorkOrderInput },
        }),
        toastPromiseOptions(t, 'update', 'task'),
        true
      );
      if (!data) throw new Error('Data Error');
      return data.updateWorkOrder as IWorkOrder;
    },
    [updateWorkOrderMutation, t, toastPromise]
  );

  return {
    addWorkOrder,
    updateWorkOrder,
  };
};
